/* eslint-disable simple-import-sort/sort */
// @flow

// Shared Pages
import dataGridRowFooterContentTranslations from 'platform_web/components/DataGridRowFooter/DataGridRowFooterContent/localizedStrings.json'
import editPasswordFormTranslations from 'shared/ui/Forms/Fields/EnterPassword/localizedStrings.json'
import loadMoreComponentTranslations from 'react-ui/components/LoadMore/localizedStrings.json'
import releaseWarningBannerTranslations from 'platform_web/components/ReleaseWarning/ReleaseWarningBanner/localizedStrings.json'

// User Dashboard
import activityLogTranslations from 'platform_web/features/ActivityPanel/ActivityLog/localizedStrings.json'
import activityPanelTranslations from 'platform_web/features/ActivityPanel/localizedStrings.json'
import chartHeaderTranslations from 'platform_web/components/SummaryGraph/ChartHeader/localizedStrings.json'
import clinicianDropdownTranslations from 'platform_web/components/SummaryGraph/ClinicianDropdown/localizedStrings.json'
import clinicianNoteTranslations from 'platform_web/features/ActivityPanel/ClinicianNote/localizedStrings.json'
import requestAssessmentButtonTranslations from 'platform_web/components/SummaryGraph/RequestAssessmentButton/localizedStrings.json'
import staffDashboardSelectionTranslations from 'platform_web/components/Staff/StaffDashboardSelection/localizedStrings.json'
import staffNavigationTabsTranslations from 'platform_web/features/StaffNavigationTabs/localizedStrings.json'
import summaryGraphTranslations from 'platform_web/components/SummaryGraph/SummaryGraph/localizedStrings.json'
import supportTaskButtonTranslations from 'platform_web/components/SummaryGraph/SupportTaskButton/localizedStrings.json'
import trackableChartToggleTranslations from 'platform_web/components/SummaryGraph/TrackableChartToggle/localizedStrings.json'
import usePersonalDetailsTranslations from 'react-ui/hooks/usePersonalDetails/localizedStrings.json'

const sharedTranslations = {
  en: {
    activityLogComponent: activityLogTranslations.en,
    activityPanelComponent: activityPanelTranslations.en,
    chartHeaderComponent: chartHeaderTranslations.en,
    clinicianDropdownComponent: clinicianDropdownTranslations.en,
    clinicianNoteComponent: clinicianNoteTranslations.en,
    dataGridRowFooterContentComponent: dataGridRowFooterContentTranslations.en,
    enterPasswordPage: editPasswordFormTranslations.en,
    loadMoreComponent: loadMoreComponentTranslations.en,
    releaseWarningBannerComponent: releaseWarningBannerTranslations.en,
    requestAssessmentButtonComponent: requestAssessmentButtonTranslations.en,
    staffNavigationTabs: staffNavigationTabsTranslations.en,
    summaryGraphComponent: summaryGraphTranslations.en,
    supportTaskButtonComponent: supportTaskButtonTranslations.en,
    trackableChartToggleComponent: trackableChartToggleTranslations.en,
    staff: {
      staffDashboardSelectionComponent: staffDashboardSelectionTranslations.en,
    },
    hooks: {
      usePersonalDetailsHook: usePersonalDetailsTranslations.en,
    },
  },
  fr: {
    activityLogComponent: activityLogTranslations.fr,
    activityPanelComponent: activityPanelTranslations.fr,
    chartHeaderComponent: chartHeaderTranslations.fr,
    clinicianDropdownComponent: clinicianDropdownTranslations.fr,
    clinicianNoteComponent: clinicianNoteTranslations.fr,
    dataGridRowFooterContentComponent: dataGridRowFooterContentTranslations.fr,
    enterPasswordPage: editPasswordFormTranslations.fr,
    loadMoreComponent: loadMoreComponentTranslations.fr,
    releaseWarningBannerComponent: releaseWarningBannerTranslations.fr,
    requestAssessmentButtonComponent: requestAssessmentButtonTranslations.fr,
    staffNavigationTabs: staffNavigationTabsTranslations.fr,
    summaryGraphComponent: summaryGraphTranslations.fr,
    supportTaskButtonComponent: supportTaskButtonTranslations.fr,
    trackableChartToggleComponent: trackableChartToggleTranslations.fr,
    staff: {
      staffDashboardSelectionComponent: staffDashboardSelectionTranslations.fr,
    },
    hooks: {
      usePersonalDetailsHook: usePersonalDetailsTranslations.fr,
    },
  },
}

export default sharedTranslations
