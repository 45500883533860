// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { withFormik } from 'formik'
import Link from 'found/Link'
import * as yup from 'yup'

import { Form } from 'shared/ui/Forms'
import { EnterPassword } from 'shared/ui/Forms/Fields'
import { Layout as l } from 'shared/ui/Styles'
import { Box, Button } from 'care-ui'

import s from './PasswordForms.scss'

import type { FormikProps } from 'services/flow'

export type EditPasswordFormValues = {
  password: string,
  password_confirmation: string,
}

type EditPasswordFormPropsType = FormikProps<EditPasswordFormValues> & {
  onSubmit: (e: Event) => void,
  status: {
    error?: string,
  },
  translation: TFunction,
  values: EditPasswordFormValues,
}

const EditPasswordForm = (props: EditPasswordFormPropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    status = {},
    touched,
    values,
    translation,
  } = props

  return (
    <Box width="400px">
      <Form onSubmit={handleSubmit}>
        {status.error && <p className={s.mainError}>{status.error}</p>}

        <EnterPassword
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          userPasswordConfirm={values.password_confirmation}
          lightGrayLabel
          fullWidth
          hasConfirm
        />

        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {translation('editPasswordForm.saveButton')}
        </Button>

        <p>
          <Link to="/users/sign_in" className={l.smallLink}>
            {translation('backToLogin')}
          </Link>
        </p>
      </Form>
    </Box>
  )
}

const FormikEnhancedEditPasswordForm = withFormik({
  mapPropsToValues: () => ({ password: '', password_confirmation: '' }),

  // Submission handler
  handleSubmit: (values, { props, setSubmitting, setStatus }) =>
    props.onSubmit(values, setSubmitting, setStatus),

  validationSchema: props => {
    const { translation } = props

    return yup.object().shape({
      password: yup
        .string()
        .label('password')
        .min(6, translation('editPasswordForm.passwordMin'))
        .required(translation('editPasswordForm.passwordRequired')),
      password_confirmation: yup
        .string()
        .label('password_confirmation')
        .oneOf(
          [yup.ref('password'), null],
          translation('editPasswordForm.passwordMatch'),
        )
        .required(translation('editPasswordForm.passwordValidationMessage')),
    })
  },
})(EditPasswordForm)

const EditPasswordFormWithTranslation = (props: any) => {
  const { t: translation } = useTranslation('auth', {
    keyPrefix: 'editPasswordPage',
  })

  return <FormikEnhancedEditPasswordForm {...props} translation={translation} />
}

export default EditPasswordFormWithTranslation
