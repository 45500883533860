// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns/fp'
import Link from 'found/Link'

import Copy from 'react-ui/assets/icons/copy-text.svg'
import doWithClinician from 'react-ui/assets/icons/speech-bubble.svg'
import Icon from 'react-ui/components/Icon'
import EmrDisplay from 'react-ui/components/IndividualUserAdditionalInformationCard/EditEmrModal'
import { useFeatureToggle } from 'react-ui/hooks/useFeatureToggle'
import { usePolicies } from 'react-ui/hooks/usePolicies'
import { userLastSeen } from 'services/user'
import { createFlash } from 'platform_web/containers/Flash'
import { ActivityLogModal } from 'platform_web/features/ActivityPanel'
import { shortDateFormat } from 'platform_web/services/dateTime'

import StratifiedScoringTooltip from '../components/StratifiedScoringTooltip'

import { type FelaPropsType } from 'react-ui/typing'
import { type IndividualsTable_individual } from '../query/__generated__/IndividualsTable_individual.graphql'

type PropsType = FelaPropsType & {
  canEditEmr?: boolean,
  individual: IndividualsTable_individual,
  showFlag?: boolean,
}

const styleRules = ({ theme }) => {
  const valueStyles = {
    display: 'inline-block',
    padding: '7px 16px',
    overflow: 'hidden',
    width: '50%',
    color: theme.care.palette.text.positive,
    ...theme.care.typography.desktop.bodyLg,
  }

  return {
    extendedRow: {
      width: '100%',
      paddingTop: '5px',
      paddingLeft: '44px',
      minHeight: '98px',
    },
    withCliFlag: {
      height: '20px',
      width: 'auto',
      marginRight: '14px',
      alignItems: 'flex-start',
    },
    copyTextIcon: {
      height: '17px',
      scale: '1.5',
      width: '15px',
      marginLeft: '7px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    group: {
      width: 'calc(calc(100% - 16px)/3)',
      ':first-child': {
        width: 'calc(calc(100% + 32px)/3)',
      },
    },
    individual: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.care.palette.text.positive,
      ...theme.care.typography.desktop.bodyLgBold,
      padding: '7px 16px',
      display: 'flex',
    },
    firstGroupValue: {
      ...valueStyles,
      width: '66%',
    },
    emailValue: {
      ...valueStyles,
      width: '66%',
      wordBreak: 'break-all',
    },
    value: {
      ...valueStyles,
    },
    titleText: {
      marginRight: '5px',
    },
    stratifiedLink: {
      display: 'flex',
      '& a': {
        marginRight: '5px',
      },
    },
  }
}

const StaffIndividualsTableFooter = ({
  styles,
  canEditEmr,
  individual,
  showFlag,
}: PropsType) => {
  const {
    initial_questionnaire_status,
    user,
    user: { personal_details: { date_of_birth }, stratified_scoring_result },
    individual_detail,
    do_with_clinician: { question_sets = [] },
    tenant,
  } = individual
  const policies = usePolicies()

  const { t: translation } = useTranslation('staff', {
    keyPrefix: 'individual.staffIndividualsTableFooterPage',
  })

  const clinicianView = policies?.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT

  const { status: stratifiedStatus, cluster_probabilities } =
    stratified_scoring_result || {}
  const { stratified_scoring } = tenant || {}

  const stratifiedStatusLink = (
    <div className={styles.stratifiedLink}>
      <Link
        to={{
          name: 'user_health_history',
          params: {
            id: user.id,
          },
        }}
      >
        {stratifiedStatus}
      </Link>
      <StratifiedScoringTooltip clusterProbabilities={cluster_probabilities} />
    </div>
  )

  const questionnaireStatus =
    stratified_scoring &&
    stratifiedStatus &&
    clinicianView &&
    initial_questionnaire_status === 'Completed'
      ? stratifiedStatusLink
      : initial_questionnaire_status

  const questionSetLabels = question_sets.map(({ label }) => label).join(', ')

  const helpRequestLabel = (
    <>
      <Icon as={doWithClinician} className={styles.withCliFlag} />
      <span>{translation('helpRequested')}</span>
    </>
  )
  const { lastSeenDate } = userLastSeen(user)

  const formattedLastSeenDate = lastSeenDate
    ? `${formatDistance(new Date())(lastSeenDate)} ${translation(
        'lastSeenFormat',
      )}`
    : ''

  const recentActivityTrigger = (
    <ActivityLogModal triggerLabel={formattedLastSeenDate} userId={user.id} />
  )
  const { ACTIVITY_LOG: showActivityLog } = useFeatureToggle()

  const lastSeenLabel =
    showActivityLog && clinicianView
      ? recentActivityTrigger
      : formattedLastSeenDate

  const lastSeenAtText = lastSeenDate ? lastSeenLabel : ''

  const dateOfBirth = date_of_birth ? shortDateFormat(date_of_birth) : ''

  const getDetails = () => {
    if (!tenant || !individual_detail) return false
    const { id: detailsId, emr_user_id: currentEmrId } = individual_detail
    const { emr_integration, emr_status: emrStatus } = tenant
    if (emrStatus === 'none' || !emr_integration) return false
    const { emr_provider: { user_id_field_name: emrName } } = emr_integration

    return {
      emrName,
      currentEmrId,
      detailsId,
    }
  }
  const details = getDetails()
  const emrField = { title: '', value: <span> </span> }

  if (details) {
    const { emrName, currentEmrId } = details

    emrField.title = emrName || ''
    emrField.value = canEditEmr ? (
      <EmrDisplay staff individual={individual} emrEditable />
    ) : (
      <span>{currentEmrId}</span>
    )
  }

  const requestLabelItems = {
    id: 'group3',
    items: [
      {
        key: 'helpRequestLabel',
        title: helpRequestLabel,
        value: questionSetLabels,
      },
    ],
  }

  const dataSet = [
    {
      id: 'group1',
      items: [
        {
          title: translation('group.one.preferredName'),
          value: user.preferred_name,
        },
        { title: translation('group.one.dob'), value: dateOfBirth },
        { title: translation('group.one.email'), value: user.email },
      ],
    },
    {
      id: 'group2',
      items: [
        { title: translation('group.two.lastActive'), value: lastSeenAtText },
        {
          title: translation('group.two.initialQuestionnaire'),
          value: questionnaireStatus,
        },
        emrField,
      ],
    },
  ]

  if (showFlag && question_sets.length > 0) {
    dataSet.push(requestLabelItems)
  }

  const handleEmailClick = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() =>
        createFlash({
          message: translation('copyMessageSuccess'),
          type: 'success',
          timeout: 1500,
        }),
      )
      .catch(() =>
        createFlash({
          message: translation('copyMessageFailed'),
          type: 'error',
          timeout: 1500,
        }),
      )
  }

  const ItemGroups = ({ items, id }) => {
    return items.map(item => {
      const emailRow = item.title === translation('group.one.email')

      const firstGroupValue = emailRow
        ? styles.emailValue
        : styles.firstGroupValue

      const valueStyle = id === 'group1' ? firstGroupValue : styles.value

      const emailClickHandler = emailRow
        ? () => handleEmailClick(String(item.value))
        : undefined

      return (
        <div className={styles.individual} key={item.key || item.title}>
          <span className={styles.title}>
            <div className={styles.titleText}>{item.title}</div>
          </span>
          <span className={valueStyle} aria-hidden="true">
            {item.value}

            {emailRow && (
              <Icon
                as={Copy}
                onClick={emailClickHandler}
                className={styles.copyTextIcon}
              />
            )}
          </span>
        </div>
      )
    })
  }

  const ContainerGroups = ({ groups }) => {
    return groups.map(({ id, items }) => (
      <div className={styles.group} key={id}>
        <ItemGroups items={items} key={id} id={id} />
      </div>
    ))
  }

  const renderStaffIndividualsTableFooter = (
    <div className={styles.extendedRow} data-component-id="individual-details">
      <div className={styles.container}>
        <ContainerGroups groups={dataSet} />
      </div>
    </div>
  )

  return renderStaffIndividualsTableFooter
}

export default connect(styleRules)(StaffIndividualsTableFooter)
