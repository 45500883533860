// @flow

import React, { useEffect } from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'

import type { UseEmployeesTableReturnType } from '../hooks/useEmployeesTable'
import { employeesTableFragments, employeesTablePaginatedQuery } from '../query/EmployeesTable'

import StaffEmployeesTable from './StaffEmployeesTable'


type PropsType = {
  multiSelectPayload: any,
  useEmployeesTablePayload: UseEmployeesTableReturnType,
}

export const Table = (props: PropsType) => {
  const { useEmployeesTablePayload, multiSelectPayload } = props

  const { count, queryVariables } = useEmployeesTablePayload

  const {
    allSelected,
    deselectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload

  const queryData = useLazyLoadQuery(
    employeesTablePaginatedQuery,
    queryVariables,
  )

  const { data, loadNext, hasNext } = usePaginationFragment(
    employeesTableFragments.user,
    queryData?.viewer?.currentUser,
  )

  const { staff_admin } = data

  // MUTLI_SELECT: If all selected, and load more is clicked, this adds newly loaded data to selected items
  useEffect(
    () => {
      if (!allSelected) return

      const staffData = (staff_admin?.staff.edges || []).filter(Boolean)

      const ids = staffData.map(item => item?.node?.roles.map(role => role.id)).flat()

      const filteredIds = ids.filter(id => !deselectedRoleIds.includes(id))

      setMultiSelectState({ selectedRoleIds: filteredIds })
    },
    [data],
  )

  return (
    <>
      {/* Table Component */}
      <StaffEmployeesTable
        staff_admin={staff_admin}
        queryVariables={queryVariables}
        multiSelectPayload={multiSelectPayload}
      />

      {/* Pagination Loader */}
      {hasNext && <LoadMore handleClick={() => loadNext(count)} />}
    </>
  )
}
