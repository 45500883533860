// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'

import Heading from 'react-ui/components/Heading'
import { changePasswordRequest } from 'shared/services/api/registration'
import Sentry from 'shared/services/sentry'
import { Layout as l } from 'shared/ui/Styles'
import { Button } from 'care-ui'

import EditPasswordForm, {
  type EditPasswordFormValues,
} from '../components/EditPasswordForm'
import ResponsiveForm from '../ResponsiveForm'

import type {
  FormikSetStatusFunc,
  FormikSetSubmittingFunc,
} from 'services/flow'

const EditPasswordPage = () => {
  const [updated, setUpdated] = useState(false)
  const { match } = useRouter()

  const { t: translation } = useTranslation('auth', {
    keyPrefix: 'editPasswordPage',
  })

  const formatError = (errors: any) => {
    if (errors?.reset_password_token) {
      return `${translation('errors.resetPasswordTokenMessage')} ${
        errors.reset_password_token[0]
      }`
    }
    if (errors?.password) {
      return `${translation('errors.password')}${errors.password[0]}`
    }
    return translation('errors.general')
  }

  const updatePassword = (
    values: EditPasswordFormValues,
    setSubmitting: FormikSetSubmittingFunc,
    setStatus: FormikSetStatusFunc,
  ) => {
    const { reset_password_token } = match.location.query
    const payload = { ...values, reset_password_token }

    changePasswordRequest(payload, {
      onSuccess: () => {
        setUpdated(true)
      },
      onError: error => {
        switch (error.response.status) {
          case 422:
            error.response
              .json()
              .then(body => {
                const message = formatError(body?.errors)
                setStatus({ error: message })
                setSubmitting(false)
                return body
              })
              .catch(Sentry.captureException)
            break

          default:
            setStatus({
              error: translation('errors.status'),
            })
            setSubmitting(false)
            break
        }
      },
    })
    setSubmitting(false)
  }

  return (
    <ResponsiveForm>
      <div>
        {updated ? (
          <>
            <Heading
              level={3}
              extend={({ theme }) => ({
                color: theme.palette.component.primary.base,
              })}
            >
              {translation('success')}
            </Heading>

            <p>{translation('passwordUpdateMessage')}</p>

            <Button to="/users/sign_in" variant="primary">
              {translation('backToLogin')}
            </Button>
          </>
        ) : (
          <>
            <Heading
              level={3}
              extend={({ theme }) => ({
                color: theme.palette.component.primary.base,
              })}
            >
              {translation('createPasswordHeading')}
            </Heading>

            <div className={l['my-2']} />

            <EditPasswordForm onSubmit={updatePassword} />
          </>
        )}
      </div>
    </ResponsiveForm>
  )
}

export default EditPasswordPage
