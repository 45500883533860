// @flow

import React from 'react'
import classNames from 'classnames'
import { omit } from 'lodash'

import DataGridRowFooterRenderer from 'components/DataGridRowFooter/DataGridRowFooterRenderer'
import DataGrid, { BasicCellRenderer } from 'react-ui/components/DataGrid'
import {
  staffCliniciansColumn,
  staffFormulaColumnConfig,
  staffIndividualColumnConfig,
} from 'react-ui/components/Tables/columns'
import IndividualsTableScoresActions from 'platform_web/pages/Staff/Individuals/IndividualsTableScoresActions'

import type { UseMultiSelectReturnTypes } from '../../hooks/useMultiSelect'
import type { ClinicianIndividualAssignmentStatusEnumType } from '../components/IndividualsTable'
import StaffIndividualsTableFooter from '../StaffIndividualsTableFooter'

import { type IndividualsTable_individual } from '../query/__generated__/IndividualsTable_individual.graphql'
import type { IndividualsTable_user } from '../query/__generated__/IndividualsTable_user.graphql'

type IndividualsTableUserType = {
  multiSelectPayload: UseMultiSelectReturnTypes,
  queryVariables: { [string]: string | boolean },
  user: IndividualsTable_user,
}

const showFlag = true

const customStyle = () => ({
  DataGrid: {
    '& button': {
      left: '-4px',
      top: '15px',
      padding: 0,
    },
    maxHeight: 'initial',
  },
  headerVisible: {
    '& [data-component-id="table-column-head"]': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& div': {
      ':last-child': {
        borderColor: 'transparent',
      },
    },
  },
  headerHidden: {
    '& [data-component-id="table-column-head"]': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  body: {
    overflow: 'hidden',
    '& .TextCell': {
      '& div': {
        '& div': {
          overflow: 'visible',
          '& span': {
            '&:nth-child(2)': {
              textTransform: 'uppercase',
            },
          },
        },
        '&:first-child': {
          paddingTop: '3.5px',
        },
      },
    },
    '& .BasicCellRenderer': {
      height: '90px',
      padding: '16px 0 0 16px',
      '&:last-child': {
        padding: 0,
        '& > div': {
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& button': {
            margin: 0,
            padding: '1rem',
          },
        },
      },
      '&:nth-child(6)': {
        padding: '14px 0 0 16px',
        overflow: 'auto',
        display: 'flex',
      },
      '&:first-child': {
        paddingTop: '31px',
        paddingLeft: '60px',
        '& div': {
          '& div': {
            maxWidth: 'none',
          },
        },
      },
      '& div': {
        padding: '0',
      },
    },
  },
  rowParentBorder: {
    position: 'relative',
  },
})

const renderRow = (
  data,
  index,
  props,
  children,
  footer,
  multiSelectPayload,
) => {
  const { componentId } = props
  const { id, initial_role_status } = data
  const rowId = componentId && id ? `${componentId}-${id}` : ''
  const newProps = omit(
    {
      ...props,
      className: classNames(props.className, rowId),
    },
    ['componentId', 'border', 'filterStatus', 'assigned'],
  )
  let shouldRender = false
  if (props.assigned) {
    shouldRender =
      initial_role_status === 'Active' || initial_role_status === 'Invited'
  } else {
    shouldRender = initial_role_status === props.filterStatus
  }

  return (
    shouldRender && (
      <div key={props.key} className={props.border} data-testid={rowId}>
        <div {...newProps}>{children}</div>
        {footer && (
          <DataGridRowFooterRenderer
            content={footer(data)}
            isMultiSelectable
            multiSelectPayload={multiSelectPayload}
          />
        )}
      </div>
    )
  )
}

const ScoresIndividualsTableComponent = (
  tableProps: IndividualsTableUserType,
) => {
  const {
    user,
    queryVariables,
    user: { staff_individuals_page_actions: { scores } },
    multiSelectPayload,
  } = tableProps

  const { status: filterStatus, assigned } = queryVariables
  const individualData = (user?.individuals?.edges || []).filter(Boolean)
  const important_formulas = user?.tenant?.important_formulas || []
  const importantFormulasIDs = important_formulas.map(f => f.formula.id)

  const formulasForUser = (importantFormulaResults, _escalated) => {
    const nullFormula = {
      label: 'No Rating',
      value: 0,
    }

    const selectedImportantFormulaResults = importantFormulaResults
      .filter(ifr => importantFormulasIDs.includes(ifr.formula.id))
      .reduce(
        (object, ifr) => ({
          ...object,
          [ifr.formula.id]: {
            sortKey: 'formulaResult',
            formula_range: ifr.formula_range || nullFormula,
            trending: ifr.trending,
            time_since_updated: ifr.time_since_updated,
            last_updated_at: ifr.last_updated_at,
            escalated: _escalated,
          },
        }),
        {},
      )
    return selectedImportantFormulaResults
  }

  const columnWidth = ({
    isfirstColumn,
    isLastColumn,
  }: {
    isLastColumn?: boolean,
    isfirstColumn?: boolean,
  }): string => {
    const firstColumnWidth = `calc(calc(100% + 300px)/6)`
    const lastColumnWidth = `calc(calc(100% - 300px)/6)`
    const allColumnWidth = `calc(calc(100% - 60px)/6)`

    if (isfirstColumn) return firstColumnWidth
    if (isLastColumn) return lastColumnWidth

    return allColumnWidth
  }

  const importantFormulasConfig = important_formulas.reduce(
    (memo, { formula, label }) => ({
      ...memo,
      [formula.id]: {
        ...staffFormulaColumnConfig,
        sortable: false,
        width: columnWidth({ isfirstColumn: false }),
        label,
      },
    }),
    {},
  )

  const tableFooter = ({ individual, individual: { role_status } }) => {
    const editAllowed = ['Active', 'Invited'].includes(role_status)
    const canEditEmr = editAllowed && scores.includes('medical_director')

    return (
      <StaffIndividualsTableFooter
        canEditEmr={canEditEmr}
        individual={individual}
        showFlag
      />
    )
  }

  return (
    <DataGrid
      extend={customStyle}
      renderRow={renderRow}
      data={individualData}
      componentId="ScoresIndividualsTableBody"
      columns={['individual', ...importantFormulasIDs, 'clinicians', 'actions']}
      footer={tableFooter}
      mapRowProps={(rowData, _, rowProps) => ({
        ...rowProps,
        filterStatus,
        assigned,
      })}
      mapData={edges => {
        if (!edges) {
          throw new Error('No Clinician Assignment found.')
        }

        const {
          questionnaireDueAt,
          questionnaireLastAnsweredAt,
          clinicianPreviouslyProvidedFeedback,
          clinicianCanProvideFeedback,
          assignmentStatus,
          node: individual,
        } = edges

        const {
          id,
          important_formula_results,
          duty_of_care_clinicians,
          escalated,
          role_status,
          user: { id: userId },
          tenant,
        } = individual

        let questionnaireId = null

        if (tenant && tenant.summary_questionnaire) {
          questionnaireId = tenant.summary_questionnaire.id
        }
        return {
          id,
          individual,
          showFlag,
          assignmentStatus,
          initial_role_status: role_status,
          clinicians: {
            clinicians: duty_of_care_clinicians,
            canProvideFeedback: clinicianCanProvideFeedback,
            hasProvidedFeedback: clinicianPreviouslyProvidedFeedback,
            questionnaireDueAt,
            questionnaireLastAnswered: questionnaireLastAnsweredAt,
            questionnaireId,
            userId,
          },
          ...formulasForUser(important_formula_results, escalated),
          actions: {
            individual,
          },
        }
      }}
      columnConfig={{
        individual: {
          ...staffIndividualColumnConfig,
          sortable: false,
          width: columnWidth({ isfirstColumn: true }),
        },
        ...(importantFormulasConfig: any),
        clinicians: {
          ...staffCliniciansColumn,
          sortable: false,
          width: columnWidth({ isLastColumn: true }),
        },
        actions: {
          width: '40px',
          label: ' ',
          sortable: false,
          format: null,
          cellRenderer: (
            data: {
              assignmentStatus: ClinicianIndividualAssignmentStatusEnumType,
              individual: IndividualsTable_individual,
            },
            key: string,
            props: any,
          ) => {
            const { individual, assignmentStatus } = data
            return (
              <BasicCellRenderer key={key} {...(props: any)}>
                <IndividualsTableScoresActions
                  user={user}
                  individual={individual}
                  assignmentStatus={assignmentStatus}
                />
              </BasicCellRenderer>
            )
          },
        },
      }}
      multiSelectPayload={multiSelectPayload}
    />
  )
}

export default ScoresIndividualsTableComponent
