// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicFooter__footer___XjYFw{display:flex;z-index:8;position:fixed;right:0;bottom:0;left:0;height:5rem;padding-right:2rem}.PublicFooter__footer___XjYFw.PublicFooter__relative___qovd5{position:relative;margin:4rem 0}.PublicFooter__footer___XjYFw.PublicFooter__left___nMOfZ{justify-content:flex-start}.PublicFooter__footer___XjYFw.PublicFooter__left___nMOfZ .PublicFooter__footerContainer___ffS8i{margin-left:5rem}.PublicFooter__footer___XjYFw.PublicFooter__right___tQmxj{justify-content:flex-end}.PublicFooter__footer___XjYFw.PublicFooter__right___tQmxj .PublicFooter__footerContainer___ffS8i{margin-right:5rem}.PublicFooter__footer___XjYFw.PublicFooter__white___TNcMe a{color:#fff}.PublicFooter__footer___XjYFw.PublicFooter__white___TNcMe a:hover{color:rgba(255,255,255,.7)}.PublicFooter__links___sAcNn{align-items:flex-end;max-width:40%}.PublicFooter__footerContainer___ffS8i{display:flex}.PublicFooter__footerLinks___iOqww{flex-grow:2;list-style:none;font-weight:500}.PublicFooter__footerLinks___iOqww li{display:inline-block;padding:0 .5rem;line-height:1.566rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `PublicFooter__footer___XjYFw`,
	"relative": `PublicFooter__relative___qovd5`,
	"left": `PublicFooter__left___nMOfZ`,
	"footerContainer": `PublicFooter__footerContainer___ffS8i`,
	"right": `PublicFooter__right___tQmxj`,
	"white": `PublicFooter__white___TNcMe`,
	"links": `PublicFooter__links___sAcNn`,
	"footerLinks": `PublicFooter__footerLinks___iOqww`
};
export default ___CSS_LOADER_EXPORT___;
