// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'found/Link'

import Heading from 'react-ui/components/Heading'
import { Box } from 'care-ui'

type PropsType = {
  onTryAgain: () => void,
}

const NewPasswordWaiting = (props: PropsType) => {
  const { onTryAgain } = props

  const { t: translation } = useTranslation('auth', {
    keyPrefix: 'newPasswordPage',
  })

  return (
    <Box width="600px">
      <Heading
        level={3}
        extend={({ theme }) => ({
          color: theme.palette.component.primary.base,
        })}
      >
        {translation('checkEmailHeading')}
      </Heading>

      <p>{translation('emailInstructionsOne')}</p>

      <p>{translation('emailInstructionsTwo')}</p>

      <div>
        <Link to="/users/password/new" onClick={onTryAgain}>
          {translation('requestLink')}
        </Link>
      </div>
    </Box>
  )
}

export default NewPasswordWaiting
