// @flow

import { useSetState } from 'react-use'

type DefaultMultiSelectState = {
  allSelected?: boolean,
  deselectedRoleIds?: Array<string>,
  isMultiSelect?: boolean,
  selectedRoleIds?: Array<string>,
  totalCount?: number,
}
export type UseMultiSelectReturnTypes = {
  allSelected: boolean,
  deselectedRoleIds: Array<string>,
  hasSelectedRoleIds: boolean,
  isMultiSelect?: boolean,
  resetMultiSelectState: () => void,
  selectedRoleIds: Array<string>,
  selectedUsers: Array<{ roles: Array<any>, userId: string }>,
  setMultiSelectState: (args: DefaultMultiSelectState) => void,
  toggleDeselectedRoleIds: (item: string) => void,
  toggleSelectedIRoleIds: (item: string) => void,
  toggleSelectedUsers: (item: { roles: Array<any>, userId: string }) => void,
  totalCount: number,
}

const defaultMultiSelectState: DefaultMultiSelectState = {
  selectedRoleIds: [],
  deselectedRoleIds: [],
  selectedUsers: [],
  allSelected: false,
  isMultiSelect: true,
}
const useMultiSelect = (): UseMultiSelectReturnTypes => {
  const [multiSelectState, setMultiSelectState] = useSetState(
    defaultMultiSelectState,
  )

  const resetMultiSelectState = () =>
    setMultiSelectState(defaultMultiSelectState)
  const {
    isMultiSelect,
    selectedRoleIds,
    deselectedRoleIds,
    allSelected,
    selectedUsers,
    totalCount,
  } = multiSelectState

  const hasSelectedRoleIds = !!selectedRoleIds.length

  const toggleSelectedIRoleIds = (roleId: string | Array<string>) => {
    const roleIdsToToggle = Array.isArray(roleId) ? roleId : [roleId]

    const updatedSelectedItems = roleIdsToToggle.reduce((acc, item) => {
      return acc.includes(item) ? acc.filter(i => i !== item) : [...acc, item]
    }, selectedRoleIds)

    setMultiSelectState({ selectedRoleIds: updatedSelectedItems })
  }

  // To handle actions of employee table
  const toggleSelectedUsers = (item: { roles: Array<any>, userId: string }) => {
    const updatedSelectedUsers = selectedUsers.find(
      user => user.userId === item.userId,
    )
      ? selectedUsers.filter(i => i.userId !== item.userId)
      : [...selectedUsers, item]

    setMultiSelectState({ selectedUsers: updatedSelectedUsers })
  }

  const toggleDeselectedRoleIds = (roleId: string | Array<string>) => {
    const roleIdsToToggle = Array.isArray(roleId) ? roleId : [roleId]

    const updatedeselectedItems = roleIdsToToggle.reduce((acc, item) => {
      return acc.includes(item) ? acc.filter(i => i !== item) : [...acc, item]
    }, deselectedRoleIds)

    setMultiSelectState({ deselectedRoleIds: updatedeselectedItems })
  }

  return {
    allSelected,
    deselectedRoleIds,
    hasSelectedRoleIds,
    isMultiSelect,
    selectedRoleIds,
    selectedUsers,
    setMultiSelectState,
    totalCount,
    toggleSelectedIRoleIds,
    toggleDeselectedRoleIds,
    toggleSelectedUsers,
    resetMultiSelectState,
  }
}

export default useMultiSelect
