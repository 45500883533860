/* eslint-disable simple-import-sort/sort */
// @flow

// Staff Pages
import assignCliniciansModalTranslations from 'platform_web/pages/Staff/Individuals/components/AssignCliniciansModal/localizedStrings.json'
import dischargeModalTranslations from 'platform_web/pages/Staff/Individuals/components/DischargeModal/localizedStrings.json'
import reinviteIndividualsModalTranslations from 'platform_web/pages/Staff/Individuals/components/ReinviteModal/localizedStrings.json'
import removeIndividualsModalTranslations from 'platform_web/pages/Staff/Individuals/components/RemoveIndividualsModal/localizedStrings.json'
import resendInvitationsModalTranslations from 'platform_web/pages/Staff/Individuals/components/ResendInvitationsModal/localizedStrings.json'
import requestAssessmentsModalTranslations from 'platform_web/pages/Staff/Individuals/components/RequestAssessmentsModal/localizedStrings.json'
import staffEmployeesTableTranslations from 'platform_web/pages/Staff/Employees/components/StaffEmployeesTable/localizedStrings.json'
import staffEscalationsTableDataGridTranslations from 'platform_web/components/Staff/StaffEscalationsTableDataGrid/localizedStrings.json'
import staffIndividualsTableFooterTranslations from 'platform_web/pages/Staff/Individuals/components/StaffIndividualsTableFooter/localizedStrings.json'
import staffIndividualsTableTranslations from 'platform_web/pages/Staff/Individuals/components/IndividualsTable/localizedStrings.json'
import standardIndividualsTableComponentTranslations from 'platform_web/pages/Staff/Individuals/components/StandardIndividualsTableComponent/localizedStrings.json'

const staffTranslations = {
  en: {
    employee: {
      staffEmployeesTableComponent: staffEmployeesTableTranslations.en,
    },
    individual: {
      assignCliniciansModalPage: assignCliniciansModalTranslations.en,
      dischargeModalPage: dischargeModalTranslations.en,
      individualsTablePage: staffIndividualsTableTranslations.en,
      reinviteIndividualsModalPage: reinviteIndividualsModalTranslations.en,
      resendInvitationsModalPage: resendInvitationsModalTranslations.en,
      removeIndividualsModalPage: removeIndividualsModalTranslations.en,
      requestAssessmentModalPage: requestAssessmentsModalTranslations.en,
      staffIndividualsTableFooterPage:
        staffIndividualsTableFooterTranslations.en,
      standardIndividualsTableComponentPage:
        standardIndividualsTableComponentTranslations.en,
    },
    esclation: {
      staffEscalationsTableDataGridPage:
        staffEscalationsTableDataGridTranslations.en,
    },
  },
  fr: {
    employee: {
      staffEmployeesTableComponent: staffEmployeesTableTranslations.fr,
    },
    individual: {
      assignCliniciansModalPage: assignCliniciansModalTranslations.fr,
      dischargeModalPage: dischargeModalTranslations.fr,
      individualsTablePage: staffIndividualsTableTranslations.fr,
      reinviteIndividualsModalPage: reinviteIndividualsModalTranslations.fr,
      resendInvitationsModalPage: resendInvitationsModalTranslations.fr,
      removeIndividualsModalPage: removeIndividualsModalTranslations.fr,
      requestAssessmentModalPage: requestAssessmentsModalTranslations.fr,
      staffIndividualsTableFooterPage:
        staffIndividualsTableFooterTranslations.fr,
      standardIndividualsTableComponentPage:
        standardIndividualsTableComponentTranslations.fr,
    },
    esclation: {
      staffEscalationsTableDataGridPage:
        staffEscalationsTableDataGridTranslations.fr,
    },
  },
}

export default staffTranslations
