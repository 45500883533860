// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import { useAssessmentRequesteeContext } from 'platform_web/features/AssessmentRequests'
import { Grid, GridItem, Icon } from 'care-ui'

import AssessmentRequestsItem from '../AssessmentRequestsItem'

import {
  alertStyle,
  iconStyle,
  wrapperStyle,
} from './AssessmentRequestsLibrary.style'
import { query } from './query/AssessmentRequestsLibrary'

const AssessmentRequestsLibrary = () => {
  const {
    userId,
    assessmentRequesteeId,
    setPreSelected,
    individualId,
  } = useAssessmentRequesteeContext()
  const { css } = useFela()
  const { user } = useLazyLoadQuery(query, {
    id: userId,
    requesteeRoleId: individualId,
  })
  const { linked_individual } = user || {}
  const { requestableQuestionnaires } = linked_individual || {}

  const isIndividualRequestee = assessmentRequesteeId !== individualId

  const toggleRender = questionnaireId => {
    setPreSelected(questionnaireId)
  }

  const { t: translation } = useTranslation('assessment', {
    keyPrefix: 'assessmentRequestsFeaturesPages.assessmentRequestsLibrary',
  })

  const getTrackableCategory = params => {
    const { library_description, category } = params

    if (category === 'initial') return translation('initial')
    if (category === 'summary') return translation('summary')

    return library_description
  }

  return (
    <>
      {!isIndividualRequestee && (
        <p className={css(alertStyle)}>
          <span className={css(iconStyle)}>
            <Icon as={InfoCircle} scale={0.6} />
          </span>

          {translation('description')}
        </p>
      )}

      <Grid extend={wrapperStyle}>
        {requestableQuestionnaires.map(
          ({ label, id, library_description, category }) => {
            const trackableDescription = getTrackableCategory({
              library_description,
              category,
            })

            return (
              <GridItem xl={3} lg={4} md={6}>
                <AssessmentRequestsItem
                  key={uniqueId('assessment-requests-item-')}
                  label={label}
                  toggleList={toggleRender}
                  questionnaireId={id}
                  description={trackableDescription}
                />
              </GridItem>
            )
          },
        )}
      </Grid>
    </>
  )
}

export default AssessmentRequestsLibrary
