// @flow

import React from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { map } from 'lodash'

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import type { UseMultiRoleAssignmentReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiRoleAssignment'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import { Checkbox, FlexContainer, ScrollableBox } from 'care-ui'

import useQueryFilters from '../../hooks/useQueryFilters'
import {
  assignableCliniciansFragment,
  assignableCliniciansQuery,
} from '../../query/AssignableClinicians'

const COUNT = 25

type AssignCliniciansListPropsType = {
  multiSelectPayload: UseMultiSelectReturnTypes,
  queryVariables: QueryVariablesType,
  searchString?: string,
  toggleSelectedClinicians: (clinician: { id: string, name: string }) => void,
  useMultiRoleAssignmentPayload: UseMultiRoleAssignmentReturnTypes,
}

const AssignCliniciansList = (props: AssignCliniciansListPropsType) => {
  const {
    searchString,
    multiSelectPayload,
    queryVariables,
    toggleSelectedClinicians,
    useMultiRoleAssignmentPayload,
  } = props

  const { allSelected, deselectedRoleIds, selectedRoleIds } = multiSelectPayload

  const { tenant } = useCurrentUserRoles()

  const { filters } = useQueryFilters(queryVariables)

  const assignableCliniciansQueryVariables = {
    search: searchString,
    count: COUNT,
    tenant_id: tenant.id,
    individuals: {
      selectedRoleIds: allSelected ? [] : selectedRoleIds,
      deselectedRoleIds,
      ...(allSelected && { filters }),
    },
  }

  const queryData = useLazyLoadQuery(
    assignableCliniciansQuery,
    assignableCliniciansQueryVariables,
  )

  const { data, loadNext, hasNext } = usePaginationFragment(
    assignableCliniciansFragment,
    queryData?.viewer,
  )

  const assignableClinicians = map(
    data.tenant.multi_select_assignable_clinicians.edges,
    'node',
  )

  const {
    assignedRoleIds,
    toggleAssignedRoleIds,
  } = useMultiRoleAssignmentPayload

  const handleOnChange = (id, name) => {
    toggleAssignedRoleIds(id)

    // For Chips
    toggleSelectedClinicians({ id, name })
  }

  return (
    <ScrollableBox
      maxHeight="100%"
      maxWidth="500px"
      onScroll={hasNext ? () => loadNext(COUNT) : undefined}
    >
      <FlexContainer direction="column" gap="sm" wrap="nowrap">
        {assignableClinicians.map(assignableClinician => {
          const { id, user: { name } } = assignableClinician
          const isSelected = assignedRoleIds.includes(id)

          return (
            <Checkbox
              dataTestId="clinician"
              inputAttributes={{
                id,
                name: id,
                label: name,
                onChange: () => handleOnChange(id, name),
                value: isSelected,
              }}
            />
          )
        })}
      </FlexContainer>
    </ScrollableBox>
  )
}

export default AssignCliniciansList
