// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Banner from 'react-ui/components/Banner'
import pageReload from 'services/pageReload'
import { Button } from 'care-ui'

const ReleaseWarningBanner = () => {
  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'releaseWarningBannerComponent',
  })

  return (
    <Banner>
      <Button onClick={pageReload} variant="text">
        <b>{translation('newVersionTextOne')}</b>
        {translation('please')}
        <u>{translation('clickHere')}</u>
        {translation('reloadMessage')}
      </Button>
    </Banner>
  )
}

export default ReleaseWarningBanner
