// @flow

import { graphql } from 'react-relay'

const AssignCliniciansMutation = graphql`
  mutation ClinicianAssignmentsCreateMutation(
    $input: ClinicianAssignmentsCreateInput!
  ) {
    clinicianAssignmentsCreate(input: $input) {
      success
    }
  }
`

export default AssignCliniciansMutation
