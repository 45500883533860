// @flow

import React from 'react'

import { Box, Chip, FlexContainer, ScrollableBox } from 'care-ui'

type ClinicianType = {
  id: string,
  name: string,
}
type PropsType = {
  selectedClinicians: Array<ClinicianType>,
  toggleSelectedClinicians: (clinician: ClinicianType) => void,
}

const SelectedClinicianChips = (props: PropsType) => {
  const { selectedClinicians, toggleSelectedClinicians } = props

  if (!selectedClinicians.length) return null

  return (
    <ScrollableBox direction="horizontal" maxWidth="500px">
      <Box paddingX="xxxs">
        <FlexContainer gap="xs" wrap="nowrap">
          {selectedClinicians?.map(selectedClinician => (
            <Chip
              key={selectedClinician.id}
              label={selectedClinician.name}
              onClick={() =>
                toggleSelectedClinicians({
                  id: selectedClinician.id,
                  name: selectedClinician.name,
                })
              }
            />
          ))}
        </FlexContainer>
      </Box>
    </ScrollableBox>
  )
}

export default SelectedClinicianChips
