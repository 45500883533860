// @flow

import React from "react";

import { FlexContainer, GraphIntervalSelector } from "care-ui";

import TenantSwitcher from "./TenantSwitcher";

type InsightsPageHeaderActionsProps = {
  interval: string,
  setQueryVariables: (queryVariables: { [key: string] : string }) => void,
}

const InsightsPageHeaderActions = (props: InsightsPageHeaderActionsProps) => {
  const { interval, setQueryVariables } = props

  return ( 
    <FlexContainer alignItems="center" gap="xs" wrap="nowrap">
      <TenantSwitcher setQueryVariables={setQueryVariables} />
      
      <GraphIntervalSelector
        interval={interval}
        setQueryVariables={setQueryVariables}
      />
    </FlexContainer>
   );
}
 
export default InsightsPageHeaderActions;