// @flow

import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-relay'

import useSelectedChips from 'react-ui/hooks/useSelectedChips'
import SelectedChips from 'platform_web/components/SelectedChips/index'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import { Box, Button, FlexContainer, Modal, SearchBar } from 'care-ui'
import useSearchBar from 'care-ui/molecules/hooks/useSearchBar'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import useQueryFilters from '../../hooks/useQueryFilters'

import RequestAssessmentList from './RequestAssessmentList'

type RequestAssessmentsModalPropsType = {
  closeModal: () => void,
  isOpen: boolean,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: any,
  queryVariables: QueryVariablesType,
}

const RequestAssessmentsModal = (props: RequestAssessmentsModalPropsType) => {
  const {
    closeModal,
    mutationQuery,
    isOpen,
    multiSelectPayload,
    queryVariables,
  } = props

  const { searchString, handleOnChange, clearSearch } = useSearchBar()
  const { selectedItems, toggleSelectedItems } = useSelectedChips()

  const {
    allSelected,
    selectedRoleIds,
    deselectedRoleIds,
    resetMultiSelectState,
  } = multiSelectPayload

  const { filters } = useQueryFilters(queryVariables)

  // Commit mutation to perform action
  const [commit] = useMutation(mutationQuery)

  const sendRequest = () => {
    commit({
      variables: {
        input: {
          individuals: {
            selectedRoleIds: allSelected ? [] : selectedRoleIds,
            deselectedRoleIds,
            ...(allSelected && { filters }),
          },
          questionnaireIds: selectedItems.map(item => item.id),
        },
      },
    })

    closeModal()
    resetMultiSelectState()
  }

  // Translation
  const { t: translation } = useTranslation('staff', {
    keyPrefix: 'individual.requestAssessmentModalPage',
  })

  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      heading={translation('requestAssessments')}
      dataTestId="RequestAssessmentsModal"
      width="600px"
      footer={
        <Button
          variant="primary"
          onClick={sendRequest}
          dataTestId="submitRequest"
          disabled={!selectedItems.length}
        >
          {translation('sendRequest')}
        </Button>
      }
    >
      <FlexContainer direction="column" gap="xxxs" wrap="nowrap">
        <TextNew typography="h6" as="h">
          {translation('selectQuestionnaire')}
        </TextNew>

        <Box height={selectedItems.length ? '10vh' : '5vh'}>
          <SearchBar
            inputAttributes={{
              id: 'searchInput',
              onChange: handleOnChange,
              value: searchString,
              placeholder: translation('placeholder'),
            }}
            dataTestId="search-bar"
            ariaLabel="Search Bar"
            clearSearch={clearSearch}
            fullWidth
          />

          <SelectedChips
            selectedItems={selectedItems}
            toggleSelectedItems={toggleSelectedItems}
          />
        </Box>

        <Box height="50vh" marginY="xs">
          <Suspense fallback={<center>{translation('loading')}</center>}>
            <RequestAssessmentList
              multiSelectPayload={multiSelectPayload}
              filters={filters}
              searchString={searchString}
              selectedItems={selectedItems}
              toggleSelectedItems={toggleSelectedItems}
            />
          </Suspense>
        </Box>
      </FlexContainer>
    </Modal>
  )
}

export default RequestAssessmentsModal
