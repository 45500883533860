// @flow

import React from 'react'

import { Box, Chip, FlexContainer, ScrollableBox } from 'care-ui'

type ItemType = {
  id: string,
  label: string,
}

type ToggleDependentItemsFn = (itemId: string) => void

type PropsType = {
  selectedItems: Array<ItemType>,
  toggleDependentItems?: ToggleDependentItemsFn,
  toggleSelectedItems: (item: ItemType, toggleDependentItems?: ToggleDependentItemsFn) => void,
}

const SelectedChips = (props: PropsType) => {
  const { selectedItems, toggleSelectedItems, toggleDependentItems } = props

  if (!selectedItems.length) return null

  return (
    <ScrollableBox direction="horizontal" maxWidth="100%">
      <Box paddingX="xxxs">
        <FlexContainer gap="xs" wrap="nowrap">
          {selectedItems.map(selectedItem => (
            <Chip
              key={selectedItem.id}
              label={selectedItem.label}
              onClick={() =>
                toggleSelectedItems({
                  id: selectedItem.id,
                  label: selectedItem.label,
                }, toggleDependentItems)
              }
            />
          ))}
        </FlexContainer>
      </Box>
    </ScrollableBox>
  )
}

export default SelectedChips