// @flow

import React from 'react'

import { Chip } from 'care-ui'

import type {
  QueryVariablesType,
  UpdateQueryVariablesType,
} from '../../Individuals/hooks/useIndividualTable'

type ResetTableFilterProps = {
  queryVariables: QueryVariablesType,
  updateQueryVariables: UpdateQueryVariablesType,
}

const ResetTableFilter = (props: ResetTableFilterProps) => {
  const { queryVariables, updateQueryVariables } = props

  const { filterEscalation, helpRequested } = queryVariables || {}

  if (!filterEscalation && !helpRequested) {
    return null
  }

  const clearFilter = () => {
    updateQueryVariables({
      filterEscalation: false,
      helpRequested: false,
    })
  }

  const filterLabel = filterEscalation ? 'STB notifications' : 'Help requested'

  return <Chip label={filterLabel} onClick={clearFilter} />
}

export default ResetTableFilter
