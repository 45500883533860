// @flow

import React from 'react'
import { startCase, toLower } from 'lodash'

import { Button, DropDownMenu, DropDownMenuItem, FlexContainer } from 'care-ui'
import { ChevronDownIcon } from 'care-ui/atoms/icons-new'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

const ACTION_BUTTONS = [
  { action: 'removeRole', label: 'Remove' },
  { action: 'resendRoleInvitation', label: 'Resend' },
]

type MultiSelectActionsProps = {
  actions: Array<string>,
  selectedUsers: Array<{ roles: Array<any>, userId: string }>,
}
const MultiSelectActions = (props: MultiSelectActionsProps) => {
  const { actions, selectedUsers } = props
  // For Employees Actions, we get all selected users and their roles
  // Step 1: Extract the role_types for each user
  const roleTypesPerUser = selectedUsers?.map(user =>
    user.roles?.map(role => role.role_type),
  )

  // Step 2: Find the intersection of all users' role_types
  const commonRoleTypes = roleTypesPerUser
    ?.reduce(
      (acc, roleTypes) => acc.filter(role => roleTypes.includes(role)),
      roleTypesPerUser[0],
    )
    ?.filter(Boolean)

  // Empty State
  if (!commonRoleTypes?.length) {
    return (
      <TextNew as="p" typography="bodyMd">
        No common actions. Ensure selected rows have a common role.
      </TextNew>
    )
  }

  // Dropdown
  if (commonRoleTypes.length > 1) {
    return (
      <FlexContainer paddingX="xxs" gap="xxxs">
        {actions.map(action => {
          const actionButton = ACTION_BUTTONS.find(
            action_button => action_button.action === action,
          )
          const { label } = actionButton || {}

          return (
            <DropDownMenu
              dropDownTrigger={
                <Button variant="primary" rightIcon={ChevronDownIcon}>
                  {label === 'Remove' ? 'Remove Role' : 'Resend Invitation'}
                </Button>
              }
            >
              {commonRoleTypes?.map(roleType => (
                <DropDownMenuItem key={roleType}>
                  {`${label} ${startCase(toLower(roleType))} ${
                    label === 'Remove' ? 'Role' : 'Invitation'
                  }`}
                </DropDownMenuItem>
              ))}
            </DropDownMenu>
          )
        })}
      </FlexContainer>
    )
  }

  return (
    <FlexContainer paddingX="xxs" gap="xxxs">
      {ACTION_BUTTONS.map(({ action, label }) => {
        if (!actions.includes(action)) {
          return null
        }

        return (
          <>
            {commonRoleTypes?.map(roleType => (
              <Button key={action} variant="primary">
                {`${label} ${startCase(toLower(roleType))} ${
                  label === 'Remove' ? 'Role' : 'Invitation'
                }`}
              </Button>
            ))}
          </>
        )
      })}
    </FlexContainer>
  )
}

export default MultiSelectActions
