// @flow

// Hook to cleanup the query variables for individuals table
// cleaned up query variables are used as filters for multi-select queries

import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'

const useQueryFilters = (queryVariables: QueryVariablesType) => {
  const {
    assigned,
    filterEscalation,
    helpRequested,
    prioritise,
    status,
    search,
  } = queryVariables

  const filters = {
    assignedToMe: !!assigned,
    filterEscalation: !!filterEscalation,
    helpRequested: !!helpRequested,
    prioritiseEscalations: !!prioritise,
    roleStatus: [String(status)],
    search: String(search),
  }

  return {
    filters,
  }
}

export default useQueryFilters
