// @flow

import React from 'react'

import { DropDownItem } from 'react-ui/components/DropDown'
import { useDependentToggle } from 'react-ui/hooks/useDependentToggle'

type FuncType = {
  assignable?: boolean,
  isOpen: boolean,
  label?: string,
  onToggleOpen?: (event: SyntheticEvent<*>) => any,
  targetToggle?: () => void,
}

type PropsType = {
  styles?: { [className: string]: string },
} & FuncType

const StaffActionButtonTrigger = ({
  onToggleOpen,
  label,
  assignable,
  targetToggle,
  isOpen,
}: PropsType) => {
  useDependentToggle(!isOpen, targetToggle)

  return (
    <DropDownItem
      label={label}
      disabled={!assignable}
      title={
        !assignable
          ? 'This clinician has not onboarded or is not active'
          : undefined
      }
      onClick={onToggleOpen}
    />
  )
}

export default ({
  onToggleOpen,
  label = 'Assign Clinicians',
  assignable = true,
  targetToggle,
  isOpen,
}: FuncType) => (
  <StaffActionButtonTrigger
    onToggleOpen={onToggleOpen}
    label={label}
    assignable={assignable}
    targetToggle={targetToggle}
    isOpen={isOpen}
  />
)
