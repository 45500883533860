// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useSelector } from 'react-redux'
import { createFragmentContainer } from 'react-relay'

import useChartToggle from 'react-ui/hooks/useChartToggle'
import useRouteParams from 'react-ui/hooks/useRouteParams'
import Can from 'react-ui/utils/Can'

import ClinicianDropdown from '../ClinicianDropdown'
import RequestAssessmentButton from '../RequestAssessmentButton'
import RetakeQuestionnaireDropdown from '../RetakeQuestionnaireDropdown'
import StaffDownloadReportButton from '../StaffDownloadReportButton'
import SupportTaskButton from '../SupportTaskButton'

import {
  commonTasksButtonsIndividual,
  commonTasksButtonsUser,
} from './query/CommonTasksButtons'

import type { CommonTasksButtons_individual } from './query/__generated__/CommonTasksButtons_individual.graphql'
import type { CommonTasksButtons_user } from './query/__generated__/CommonTasksButtons_user.graphql'

type PropsType = {
  individual: CommonTasksButtons_individual,
  user: ?CommonTasksButtons_user,
}

const containerStyle = () => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const CommonTasksButtons = ({ individual, user }: PropsType) => {
  const { css } = useFela()
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const { currentRouteName } = useRouteParams()
  const isDashboardTab = currentRouteName === 'user_dashboard'
  const isHealthHistoryTab = currentRouteName === 'user_health_history'
  const { isSummaryGauge } = useChartToggle()

  if (isSummaryGauge) return null

  return (
    <div className={css(containerStyle)} data-testid="commonTasksButtons">
      {isIndividual && (
        <>
          <SupportTaskButton individual={individual} />
          <RequestAssessmentButton individual={null} />
          <RetakeQuestionnaireDropdown individual={individual} />
        </>
      )}
      <Can>
        {({ CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT: canView }) => {
          if (!canView) return null

          if (isDashboardTab) {
            return (
              <>
                <RequestAssessmentButton individual={individual} />
                <ClinicianDropdown individual={individual} user={user} />
              </>
            )
          }

          if (isHealthHistoryTab)
            return <StaffDownloadReportButton individual={individual} />

          return null
        }}
      </Can>
    </div>
  )
}

export default createFragmentContainer(CommonTasksButtons, {
  individual: commonTasksButtonsIndividual,
  user: commonTasksButtonsUser,
})
