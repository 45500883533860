// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, useRelayEnvironment } from 'react-relay'
import { type FormikProps } from 'formik'

import Heading from 'react-ui/components/Heading'
import { Welcome2Col } from 'react-ui/components/Layout'
import Link from 'react-ui/components/Link'
import { commit as commitUserMfaAuthenticate } from 'mutations/UserMfaAuthenticate'
import MfaForm from 'platform_web/pages/authentication/components/MultiFactorAuthenticationForm'

import { multiFactorAuthenticationQuery } from './query/MultiFactorAuthentication'

import type { MultiFactorAuthentication_user } from './query/__generated__/MultiFactorAuthentication_user.graphql'

type PropsType = {
  user: MultiFactorAuthentication_user,
}

type FormProps = {
  code: string,
}

const qrCodeContainerStyle = () => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '1em',
})

const qrCodeImgStyle = () => ({
  width: '40%',
  padding: '5px',
  border: '1px solid black',
})

export const MultiFactorAuthentication = (props: PropsType) => {
  const { user: { mfa_qr_code, mfa_last_verified_at } } = props

  const { t: translation } = useTranslation('auth', {
    keyPrefix: 'multiFactorAuthPage',
  })

  const { css } = useFela()

  const qrCodeImg = `data:image/svg+xml;utf8,${encodeURIComponent(mfa_qr_code)}`

  const environment = useRelayEnvironment()

  const handleSubmit = (
    { code }: FormProps,
    { setSubmitting, setStatus }: FormikProps,
  ) => {
    setStatus()

    const onCompleted = (_, errors) => {
      const errorMsg = errors?.length
        ? translation('invalidInvitationToken')
        : null
      setSubmitting(false)
      setStatus({ error: errorMsg })
    }

    commitUserMfaAuthenticate({
      environment,
      onCompleted,
      variables: {
        input: {
          code,
        },
      },
    })
  }

  const signOutMsg = mfa_last_verified_at
    ? translation('mfaLastVerifiedSignOutMessage')
    : translation('mfaNotVerifiedSignOutMessage')

  return (
    <Welcome2Col data-testid="MfaLoader">
      <Heading level={3}>{translation('secureAccountHeading')}</Heading>
      {mfa_last_verified_at ? (
        <div>
          <p>{translation('secureAccountDescriptionMfaVerified')}</p>
        </div>
      ) : (
        <>
          <div>
            <p>{translation('secureAccountDescriptionNotVerified')}</p>
          </div>
          <div className={css(qrCodeContainerStyle)}>
            <img
              alt={translation('qrCodeAlt')}
              src={qrCodeImg}
              className={css(qrCodeImgStyle)}
              id="qrCode"
            />
          </div>
        </>
      )}

      <MfaForm onSubmit={handleSubmit} />

      <Link to="signingOut" style={{ marginTop: '1rem' }}>
        {signOutMsg}
      </Link>
    </Welcome2Col>
  )
}

export const MultiFactorAuthenticationLoader = createFragmentContainer(
  MultiFactorAuthentication,
  multiFactorAuthenticationQuery,
)
