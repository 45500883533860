// @flow

import React, { type Node, useState } from 'react'
import { useFela } from 'react-fela'
import { get } from 'lodash'

import ArrowDown from 'react-ui/assets/icons/triangular-arrow-down.svg'
import ArrowRight from 'react-ui/assets/icons/triangular-arrow-right.svg'
import Icon from 'react-ui/components/Icon'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { Checkbox } from 'care-ui/index'

import {
  accordianContainer,
  contentStyle,
  extendedRowArrow,
  hrStyle,
} from './DataGridRowFooter.style'

type PropsType = {
  content?: Node,
  multiSelectPayload?: UseMultiSelectReturnTypes,
}

const DataGridRowFooterRenderer = (props: PropsType) => {
  const { content, multiSelectPayload } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleExtendedRow = () => setIsOpen(!isOpen)
  const { css } = useFela()

  // MultiSelect
  const {
    isMultiSelect,
    toggleSelectedIRoleIds,
    toggleDeselectedRoleIds,
    selectedRoleIds,
    allSelected,
    toggleSelectedUsers,
  } =
    multiSelectPayload || {}

  // For Individual Table - For Clinician Table -- For Employee Table
  const roleId =
    get(content, 'props.individual.id') ||
    get(content, 'props.roleId') ||
    get(content, 'props.roleIds')

  const isSelected = Array.isArray(roleId)
    ? roleId?.every(id => selectedRoleIds.includes(id))
    : selectedRoleIds?.includes(roleId)

  const roles = get(content, 'props.roles')
  // For Employee Table, the single row has multiple roles, hence multiple roleId
  const handleOnChange = () => {
    if (allSelected) {
      toggleDeselectedRoleIds(roleId)
    }

    if (Array.isArray(roleId)) {
      toggleSelectedIRoleIds(roleId)

      toggleSelectedUsers({ userId: get(content, 'props.user.__id'), roles })
    } else {
      toggleSelectedIRoleIds(roleId)
    }
  }

  if (!content) return null

  return (
    <>
      {isOpen && (
        <>
          <hr className={css(hrStyle)} />
          <div className={css(contentStyle)}>{content}</div>
        </>
      )}

      <div className={css(accordianContainer)}>
        {isMultiSelect && (
          <Checkbox
            dataTestId="multiselectRow"
            inputAttributes={{
              id: 'multi-select',
              name: 'multi-select',
              onChange: handleOnChange,
              checked: isSelected,
            }}
          />
        )}

        <button
          type="button"
          data-component-id="data-grid-extend-row-btn"
          className={css(extendedRowArrow)}
          onClick={toggleExtendedRow}
        >
          <span>
            <Icon
              as={isOpen ? ArrowDown : ArrowRight}
              scale={0.4}
              alt={isOpen ? 'Arrow Down' : 'Arrow Right'}
            />
          </span>
        </button>
      </div>
    </>
  )
}

export default DataGridRowFooterRenderer
