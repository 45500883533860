// @flow

import assessmentTranslations from './assessmentPages'
import authTranslations from './authPages'
import careUITranslations from './careUIPages'
import sharedTranslations from './sharedPages'
import staffTranslations from './staffPages'

const resources = {
  en: {
    assessment: assessmentTranslations.en,
    auth: authTranslations.en,
    careUI: careUITranslations.en,
    shared: sharedTranslations.en,
    staff: staffTranslations.en,
  },
  fr: {
    assessment: assessmentTranslations.fr,
    auth: authTranslations.fr,
    careUI: careUITranslations.fr,
    shared: sharedTranslations.fr,
    staff: staffTranslations.fr,
  },
}

export default resources
