/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RolesReinviteInput = {|
  clientMutationId?: ?string,
  roles: IndividualsSelectionInput,
|};
export type IndividualsSelectionInput = {|
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?IndividualsFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type IndividualsFilterInput = {|
  assignedToMe?: ?boolean,
  filterEscalation?: ?boolean,
  helpRequested?: ?boolean,
  prioritiseEscalations?: ?boolean,
  roleStatus?: ?$ReadOnlyArray<string>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type ResendRolesMutationVariables = {|
  input: RolesReinviteInput
|};
export type ResendRolesMutationResponse = {|
  +rolesReinvite: ?{|
    +success: boolean,
    +active_individuals_in_another_tenant: ?$ReadOnlyArray<string>,
  |}
|};
export type ResendRolesMutation = {|
  variables: ResendRolesMutationVariables,
  response: ResendRolesMutationResponse,
|};
*/


/*
mutation ResendRolesMutation(
  $input: RolesReinviteInput!
) {
  rolesReinvite(input: $input) {
    success
    active_individuals_in_another_tenant
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RolesReinvitePayload",
    "kind": "LinkedField",
    "name": "rolesReinvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active_individuals_in_another_tenant",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResendRolesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResendRolesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b2f6588943fd2c3b3a373c1ee976a1c",
    "id": null,
    "metadata": {},
    "name": "ResendRolesMutation",
    "operationKind": "mutation",
    "text": "mutation ResendRolesMutation(\n  $input: RolesReinviteInput!\n) {\n  rolesReinvite(input: $input) {\n    success\n    active_individuals_in_another_tenant\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76c71f7745503bb6757f95fb058b80c8';

module.exports = node;
