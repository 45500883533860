// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns/fp'

import Copy from 'react-ui/assets/icons/copy-text.svg'
import doWithClinician from 'react-ui/assets/icons/speech-bubble.svg'
import Icon from 'react-ui/components/Icon'
import { userLastSeen } from 'services/user'
import { createFlash } from 'platform_web/containers/Flash'
import { shortDateFormat } from 'platform_web/services/dateTime'

import { type FelaPropsType } from 'react-ui/typing'
import type { DataGridRowFooterContentLoader_user } from '../__generated__/DataGridRowFooterContentLoader_user.graphql'

type PropsType = FelaPropsType & {
  clinician?: boolean,
  individual?: boolean,
  showFlag?: boolean,
  user: DataGridRowFooterContentLoader_user,
}

const styleRules = ({ theme }) => {
  const valueStyles = {
    display: 'inline-block',
    padding: '7px 16px',
    overflow: 'hidden',
    width: '50%',
    color: theme.care.palette.text.positive,
    ...theme.care.typography.desktop.bodyLg,
  }

  return {
    extendedRow: {
      width: '100%',
      paddingTop: '5px',
      paddingLeft: '44px',
      minHeight: '98px',
    },
    withCliFlag: {
      height: '20px',
      width: 'auto',
      alignItems: 'flex-start',
      marginRight: '7px',
    },
    copyTextIcon: {
      height: '17px',
      scale: '1.5',
      width: '15px',
      marginLeft: '7px',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    group: {
      width: 'calc(calc(100% - 16px)/3)',
      ':first-child': {
        width: 'calc(calc(100% + 32px)/3)',
      },
    },
    individual: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.care.palette.text.positive,
      ...theme.care.typography.desktop.bodyLgBold,
      padding: '7px 16px',
      display: 'flex',
    },
    firstGroupValue: {
      ...valueStyles,
      width: '66%',
    },
    emailValue: {
      ...valueStyles,
      width: '66%',
      wordBreak: 'break-all',
    },
    emrValue: {
      ...valueStyles,
      color: '#348179',
    },
    value: {
      ...valueStyles,
    },
  }
}

const DataGridRowFooterContent = (props: PropsType) => {
  const {
    showFlag = false,
    individual = false,
    clinician = false,
    styles,
    user,
    user: { personal_details: { date_of_birth }, linked_individual },
  } = props

  const { do_with_clinician, individual_detail, initial_questionnaire_status } =
    linked_individual || {}

  const { question_sets } = do_with_clinician || {}

  const { emr_user_id } = individual_detail || {}

  const renderQuestionSetLabels =
    question_sets && question_sets.map(({ label }) => label).join(', ')

  const { t: translation } = useTranslation('shared', {
    keyPrefix: 'dataGridRowFooterContentComponent',
  })

  const helpRequestLabel = (
    <>
      <Icon as={doWithClinician} className={styles.withCliFlag} />
      <span>{translation('helpRequested')}</span>
    </>
  )

  const { lastSeenDate } = userLastSeen(user)
  const lastSeenAtText = lastSeenDate
    ? `${formatDistance(new Date())(lastSeenDate)} ${translation(
        'lastSeenFormat',
      )}`
    : ''

  const dateOfBirth = date_of_birth ? shortDateFormat(date_of_birth) : ''

  const requestLabelItems = {
    id: 'group3',
    items: [
      {
        key: 'helpRequestLabel',
        title: helpRequestLabel,
        value: renderQuestionSetLabels,
      },
    ],
  }

  const dataSet = [
    {
      id: 'group1',
      items: [
        {
          title: translation('group.one.preferredName'),
          value: user.preferred_name,
          staff: true,
        },
        {
          title: translation('group.one.dob'),
          value: dateOfBirth,
          staff: false,
        },
        {
          title: translation('group.one.email'),
          value: user.email,
          staff: false,
        },
      ],
    },
    {
      id: 'group2',
      items: [
        {
          title: translation('group.two.lastActive'),
          value: lastSeenAtText,
          staff: true,
        },
        {
          title: translation('group.two.initialQuestionnaire'),
          value: initial_questionnaire_status,
          staff: false,
        },
        {
          title: translation('group.two.medicalDirectorID'),
          value: emr_user_id,
          staff: false,
        },
      ],
    },
  ]

  if (showFlag && question_sets.length > 0) {
    dataSet.push(requestLabelItems)
  }

  const handleEmailClick = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() =>
        createFlash({
          message: translation('copyMessageSuccess'),
          type: 'success',
          timeout: 1500,
        }),
      )
      .catch(() =>
        createFlash({
          message: translation('copyMessageFailed'),
          type: 'error',
          timeout: 1500,
        }),
      )
  }

  const ItemGroups = ({ items, id }) => {
    return items.map((item, i) => {
      const emailRow = item.title === translation('group.one.email')

      const clinicianEmailRow = emailRow && clinician
      const firstGroupValue = emailRow
        ? styles.emailValue
        : styles.firstGroupValue
      const groupValue = i === 2 ? styles.emrValue : styles.value
      const valueStyle = id === 'group1' ? firstGroupValue : groupValue
      const emailClickHandler = emailRow
        ? () => handleEmailClick(String(item.value))
        : undefined
      return (
        (item.staff || individual || clinicianEmailRow) && (
          <div className={styles.individual} key={item.key || item.title}>
            <span className={styles.title}>{item.title}</span>
            <span className={valueStyle}>
              {item.value}
              {emailRow && (
                <Icon
                  as={Copy}
                  onClick={emailClickHandler}
                  className={styles.copyTextIcon}
                />
              )}
            </span>
          </div>
        )
      )
    })
  }

  const ContainerGroups = ({ groups }) => {
    return groups.map(({ id, items }) => (
      <div className={styles.group} key={id}>
        <ItemGroups items={items} id={id} />
      </div>
    ))
  }

  const renderStaffIndividualsTableFooter = (
    <div className={styles.extendedRow} data-component-id="individual-details">
      <div className={styles.container}>
        <ContainerGroups groups={dataSet} />
      </div>
    </div>
  )

  return renderStaffIndividualsTableFooter
}

export default connect(styleRules)(DataGridRowFooterContent)
