/* eslint-disable simple-import-sort/sort */
// @flow

// Auth Pages
import editPasswordTranslations from 'platform_web/pages/authentication/EditPassword/localizedStrings.json'
import editRegistrationTranslations from 'platform_web/pages/authentication/EditRegistration/localizedStrings.json'
import forgotPasswordTranslations from 'platform_web/pages/authentication/components/NewPasswordForm/localizedStrings.json'
import multiFactorAuthTranslations from 'platform_web/pages/authentication/MultiFactorAuthentication/localizedStrings.json'
import newPasswordTranslations from 'platform_web/pages/authentication/NewPassword/localizedStrings.json'
import requestInvitationTranslations from 'platform_web/pages/authentication/RequestInvitation/localizedStrings.json'
import signInPageTranslations from 'platform_web/pages/authentication/SignInPage/localizedStrings.json'

const authTranslations = {
  en: {
    editPasswordPage: editPasswordTranslations.en,
    editRegistrationPage: editRegistrationTranslations.en,
    forgotPassword: forgotPasswordTranslations.en,
    multiFactorAuthPage: multiFactorAuthTranslations.en,
    newPasswordPage: newPasswordTranslations.en,
    requestInvitationPage: requestInvitationTranslations.en,
    signInPage: signInPageTranslations.en,
  },
  fr: {
    editPasswordPage: editPasswordTranslations.fr,
    editRegistrationPage: editRegistrationTranslations.fr,
    forgotPassword: forgotPasswordTranslations.fr,
    multiFactorAuthPage: multiFactorAuthTranslations.fr,
    newPasswordPage: newPasswordTranslations.fr,
    requestInvitationPage: requestInvitationTranslations.fr,
    signInPage: signInPageTranslations.fr,
  },
}

export default authTranslations
