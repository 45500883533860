/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type IndividualsSelectionInput = {|
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?IndividualsFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type IndividualsFilterInput = {|
  assignedToMe?: ?boolean,
  filterEscalation?: ?boolean,
  helpRequested?: ?boolean,
  prioritiseEscalations?: ?boolean,
  roleStatus?: ?$ReadOnlyArray<string>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type AssignableRequestAssessmentsQueryVariables = {|
  individuals: IndividualsSelectionInput,
  tenant_id: string,
|};
export type AssignableRequestAssessmentsQueryResponse = {|
  +tenant: {|
    +multi_select_requestable_questionnares: $ReadOnlyArray<{|
      +requestable: boolean,
      +questionnaire: {|
        +id: string,
        +label: string,
        +category: ?string,
      |},
    |}>
  |}
|};
export type AssignableRequestAssessmentsQuery = {|
  variables: AssignableRequestAssessmentsQueryVariables,
  response: AssignableRequestAssessmentsQueryResponse,
|};
*/


/*
query AssignableRequestAssessmentsQuery(
  $individuals: IndividualsSelectionInput!
  $tenant_id: ID!
) {
  tenant(id: $tenant_id) {
    multi_select_requestable_questionnares(individuals: $individuals) {
      requestable
      questionnaire {
        id
        label
        category
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "individuals"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenant_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenant_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "individuals",
      "variableName": "individuals"
    }
  ],
  "concreteType": "AssessmentRequestRequestableQuestionnaireType",
  "kind": "LinkedField",
  "name": "multi_select_requestable_questionnares",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "questionnaire",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignableRequestAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignableRequestAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63e6f7dc1364059d84bc8a90f818f68e",
    "id": null,
    "metadata": {},
    "name": "AssignableRequestAssessmentsQuery",
    "operationKind": "query",
    "text": "query AssignableRequestAssessmentsQuery(\n  $individuals: IndividualsSelectionInput!\n  $tenant_id: ID!\n) {\n  tenant(id: $tenant_id) {\n    multi_select_requestable_questionnares(individuals: $individuals) {\n      requestable\n      questionnaire {\n        id\n        label\n        category\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcef04f120a71fa3532603383ced0043';

module.exports = node;
