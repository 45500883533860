// @flow

import { useCurrentUserRoles } from 'react-ui/hooks/useCurrentUserRoles'
import { usePolicies } from 'react-ui/hooks/usePolicies'

type RoleType = 'LEAD_CLINICIAN' | 'CLINICIAN' | 'ADMIN' | 'MANAGER'
type TableType =
  | 'assigned'
  | 'active'
  | 'clinician_active'
  | 'invited'
  | 'removed'
  | 'discharged'

type ACTIONS_FOR_INDIVIDUAL_TYPE = Array<{
  actionName: string,
  roles: Array<RoleType>,
  tableTypes: Array<TableType>,
}>

const ACTIONS_FOR_INDIVIDUAL: ACTIONS_FOR_INDIVIDUAL_TYPE = [
  {
    actionName: 'requestAssessments',
    tableTypes: ['assigned', 'active', 'clinician_active'],
    roles: ['LEAD_CLINICIAN', 'CLINICIAN'],
  },
  {
    actionName: 'assignClinicians',
    tableTypes: ['invited', 'active', 'clinician_active'],
    roles: ['LEAD_CLINICIAN', 'ADMIN', 'MANAGER'],
  },
  {
    actionName: 'resendInvitations',
    tableTypes: ['invited', 'removed'],
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    actionName: 'resendInvitations',
    tableTypes: ['invited'],
    roles: ['LEAD_CLINICIAN'],
  },
  {
    actionName: 'discharge',
    tableTypes: ['active'],
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    actionName: 'remove',
    tableTypes: ['invited'],
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    actionName: 'reinvite',
    tableTypes: ['discharged'],
    roles: ['ADMIN', 'MANAGER'],
  },
]

type UseIndividualActionsProps = {
  tableType: 'assigned' | 'active' | 'clinician_active' | string,
}

const useIndividualActions = (props: UseIndividualActionsProps) => {
  const { tableType } = props
  const { roleType } = useCurrentUserRoles()
  const policies = usePolicies()

  const getAllowedActions = () => {
    const allowedActions: Array<string> = ACTIONS_FOR_INDIVIDUAL.filter(
      action =>
        action.tableTypes.includes(tableType) &&
        action.roles.includes(roleType),
    ).map(action => action.actionName)

    // Policy at tenant level: CAN_ASSIGN_CLINICIANS
    if (
      !policies.CAN_ASSIGN_CLINICIANS &&
      allowedActions.includes('assignClinicians')
    ) {
      const allowedActionsWithPolicies: Array<string> = allowedActions.filter(
        actionName => actionName !== 'assignClinicians',
      )

      return allowedActionsWithPolicies
    }

    return allowedActions
  }

  return {
    actions: getAllowedActions(),
  }
}

export default useIndividualActions
