// @flow

import React from 'react'

import { SearchBar } from 'care-ui'

import type {
  QueryVariablesType,
  UpdateQueryVariablesType,
} from '../../Individuals/hooks/useIndividualTable'

type PropsType = {
  queryVariables: QueryVariablesType,
  updateQueryVariables: UpdateQueryVariablesType,
}

const TableSearchControl = (props: PropsType) => {
  const { queryVariables, updateQueryVariables } = props

  // SEARCH FUNCTIONALITY
  const handleOnChange = (e: SyntheticInputEvent<>) => {
    updateQueryVariables({ search: e.target.value })
  }

  const clearSearch = () => {
    updateQueryVariables({ search: '' })
  }

  return (
    <SearchBar
      inputAttributes={{
        id: 'searchInput',
        onChange: handleOnChange,
        value: String(queryVariables?.search) || '',
        placeholder: 'Search',
      }}
      dataTestId="search-bar"
      ariaLabel="Search Bar"
      clearSearch={clearSearch}
    />
  )
}

export default TableSearchControl
