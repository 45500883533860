/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
export type ClinicianAssignmentsCreateInput = {|
  clientMutationId?: ?string,
  clinicians: RolesSelectionInput,
  individuals: IndividualsSelectionInput,
|};
export type RolesSelectionInput = {|
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?RolesFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type RolesFilterInput = {|
  assignmentStatus?: ?boolean,
  excludeDischarged?: ?boolean,
  roleTypes: $ReadOnlyArray<RoleTypes>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
  status?: ?$ReadOnlyArray<string>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type IndividualsSelectionInput = {|
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?IndividualsFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type IndividualsFilterInput = {|
  assignedToMe?: ?boolean,
  filterEscalation?: ?boolean,
  helpRequested?: ?boolean,
  prioritiseEscalations?: ?boolean,
  roleStatus?: ?$ReadOnlyArray<string>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
|};
export type ClinicianAssignmentsCreateMutationVariables = {|
  input: ClinicianAssignmentsCreateInput
|};
export type ClinicianAssignmentsCreateMutationResponse = {|
  +clinicianAssignmentsCreate: ?{|
    +success: boolean
  |}
|};
export type ClinicianAssignmentsCreateMutation = {|
  variables: ClinicianAssignmentsCreateMutationVariables,
  response: ClinicianAssignmentsCreateMutationResponse,
|};
*/


/*
mutation ClinicianAssignmentsCreateMutation(
  $input: ClinicianAssignmentsCreateInput!
) {
  clinicianAssignmentsCreate(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ClinicianAssignmentsCreatePayload",
    "kind": "LinkedField",
    "name": "clinicianAssignmentsCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClinicianAssignmentsCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClinicianAssignmentsCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e304625f93de58d328eac0f589746661",
    "id": null,
    "metadata": {},
    "name": "ClinicianAssignmentsCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ClinicianAssignmentsCreateMutation(\n  $input: ClinicianAssignmentsCreateInput!\n) {\n  clinicianAssignmentsCreate(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f9a79f0aa36cf7e4ffc7878f14abdf9';

module.exports = node;
