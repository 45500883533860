// @flow

import React, { Suspense, useEffect } from 'react'

import TinyLoader from 'react-ui/components/LoadingSpinner/TinyLoader'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import { useAuthPolicy } from 'platform_web/features/Policies'
import StaffNavigationTabs from 'platform_web/features/StaffNavigationTabs'
import { Button, Container, Divider, FlexContainer } from 'care-ui'

import useCliniciansTable from '../hooks/useCliniciansTable'
import useMultiSelect from '../hooks/useMultiSelect'

import CliniciansTableControl from './ClinciansTableControl'
import CliniciansTable from './components/CliniciansTable'
import useClinicianActions from './hooks/useClinicianActions'

const ACTION_BUTTONS = [
  { action: 'assignIndividuals', label: 'Assign individual' },
]

const StaffCliniciansPage = () => {
  const useIndividualTablePayload = useCliniciansTable()

  const multiSelectPayload = useMultiSelect()
  const {
    allSelected,
    hasSelectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload

  const { actions } = useClinicianActions()

  // Feature Toggle
  const enableMultiSelect = window.GLOBALS?.features?.MULTI_SELECT

  useEffect(() => {
    setMultiSelectState({
      isMultiSelect: enableMultiSelect && !!actions.length,
    })
  }, [])

  return (
    <>
      <nav>
        <Container>
          <StaffNavigationTabs />
        </Container>
        <Divider />
      </nav>

      <main>
        <Container>
          {/* Table Controls */}
          {!hasSelectedRoleIds &&
            !allSelected && (
              <CliniciansTableControl
                useIndividualTablePayload={useIndividualTablePayload}
              />
            )}

          {/* MultiSelect Actions */}
          {(hasSelectedRoleIds || allSelected) && (
            <FlexContainer paddingX="xxs" gap="xxxs">
              {ACTION_BUTTONS.map(
                ({ action, label }) =>
                  actions.includes(action) && (
                    <Button key={action} variant="primary">
                      {label}
                    </Button>
                  ),
              )}
            </FlexContainer>
          )}

          {/* Table Data */}
          <Suspense fallback={<TinyLoader message="Loading" />}>
            <CliniciansTable
              useIndividualTablePayload={useIndividualTablePayload}
              multiSelectPayload={multiSelectPayload}
            />
          </Suspense>
        </Container>
      </main>
    </>
  )
}

export const StaffCliniciansPageWithPolicies = () => {
  const { isAuthorized, isLoading } = useAuthPolicy([
    'CAN_VIEW_LEAD_CLINICIAN_ADMIN_NAMESPACE',
    'CAN_LIST_CLINICIANS',
  ])

  if (isLoading) return null

  return isAuthorized ? <StaffCliniciansPage /> : <UnauthorizedPage />
}
