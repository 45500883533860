// @flow

import { graphql } from 'react-relay'

// Main query that includes the User fragment
export const employeesTablePaginatedQuery = graphql`
  query EmployeesTable_PaginatedQuery(
    $count: Int!
    $cursor: String
    $search: String
    $status: [String!]
    $sort: [Sortable!]
    $query_type: String!
  ) {
    viewer {
      currentUser {
        ...EmployeesTable_user
      }
    }
  }
`

// Fragment on User that includes the StaffAdmin data
export const employeesTableFragments = {
  user: graphql`
    fragment EmployeesTable_user on User 
    @refetchable(queryName: "EmployeesTableStaffAdminRefetchQuery") 
    {
      staff_admin {
        staff(
        query_type: $query_type
        search: $search
        status: $status
        sort: $sort
        first: $count
        after: $cursor
      ) @connection(key: "EmployeesTable_staff") {
        edges {
          node {
            ...EmployeesTable_staff @relay(mask: false)
          }
        }
      }
      }
    }
  `,
}

graphql`
  fragment EmployeesTable_staff on Staff {
    user {
      ...AvatarLoader_user
      ...UserCardLoader_user
      ...DataGridRowFooterContentLoader_user
      ...StaffActions_user
      email
      invitation_created_at
      legal_name
      invitation_delta
    }
    roles {
      ...EmployeesTable_role @relay(mask: false)
    }
  }
`

graphql`
  fragment EmployeesTable_role on RoleInterface {
    id
    role_type
    role_status
    description
    tenant {
      name
      requires_mfa
    }
  }
`

graphql`
  fragment EmployeesTable_staff_mutation on Staff {
    user {
      email
    }
  }
`
