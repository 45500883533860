// @flow

import React, { useState } from 'react'
import { isArray, remove } from 'lodash/fp'

import { Button, DropDownMenu } from 'care-ui'

import type {
  QueryVariablesType,
  UpdateQueryVariablesType,
} from '../../Individuals/hooks/useIndividualTable'

import TableSortForm from './TableSortForm'

export type SortOptionsType = {
  header: string,
  label: string,
}

// Needs cleanup
const sortOptionsBy = (roleStatus, sortOptions) => {
  const roleStatusSortOption = `${String(roleStatus)?.toLowerCase()}Status`
  const roleStatusSortOptions = remove(
    option =>
      option.label.endsWith('Status') && option.header !== roleStatusSortOption,
  )(sortOptions)

  return roleStatusSortOptions
}

type TableSortControlProps = {
  queryVariables: QueryVariablesType,
  sortOptions: SortOptionsType[],
  updateQueryVariables: UpdateQueryVariablesType,
}

const TableSortControl = (props: TableSortControlProps) => {
  const { sortOptions, queryVariables, updateQueryVariables } = props

  const [closeDropDown, setCloseDropDown] = useState(true)

  const roleStatus = isArray(queryVariables.status)
    ? Array(queryVariables.status)[0][0]
    : queryVariables.status

  const normalizedSortOptions = sortOptionsBy(roleStatus, sortOptions)

  const toggleDropDownMenu = () => {
    setCloseDropDown(!closeDropDown)
  }

  return (
    <DropDownMenu
      closeDropDown={closeDropDown}
      setCloseDropDown={setCloseDropDown}
      dropDownTrigger={
        <Button
          variant="secondary"
          ariaLabel="sort"
          dataTestId="SortControl"
          onClick={toggleDropDownMenu}
        >
          Sort
        </Button>
      }
    >
      <TableSortForm
        toggleDropDownMenu={toggleDropDownMenu}
        updateQueryVariables={updateQueryVariables}
        sortOptions={normalizedSortOptions}
        queryVariables={queryVariables}
      />
    </DropDownMenu>
  )
}

export default TableSortControl
