// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withFormik } from 'formik'
import { useRouter } from 'found'
import Link from 'found/Link'
import { get } from 'lodash'
import * as yup from 'yup'

import Heading from 'react-ui/components/Heading'
import { Form } from 'shared/ui/Forms'
import { Input } from 'shared/ui/Forms/Fields'
import { Layout as l } from 'shared/ui/Styles'
import { Box, Button } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import RegionSelector from '../RegionSelector'

import s from '../PasswordForms.scss'

import type { FormikProps } from 'services/flow'

export type NewPasswordFormValues = {
  email: string,
}

type NewPasswordFormPropsType = FormikProps & {
  onSubmit: (e: Event) => void,
  status: {
    error?: string,
  },
  values: NewPasswordFormValues,
}

const NewPasswordForm = (props: NewPasswordFormPropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setValues,
    status = {},
    touched,
    values,
  } = props

  const { match } = useRouter()
  const email = get(match, 'location.query.email')

  useEffect(
    () => {
      if (email) {
        setValues({ ...values, email: decodeURIComponent(email) })
      }
    },
    [email, setValues, values],
  )

  const { t: translation } = useTranslation('auth', {
    keyPrefix: 'forgotPassword',
  })

  return (
    <Box width="450px">
      <Heading
        level={3}
        extend={({ theme }) => ({
          color: theme.palette.component.primary.base,
        })}
      >
        {translation('forgotPasswordHeading')}
      </Heading>

      <TextNew typography="bodyMd">
        {translation('forgotPasswordDescription')}
      </TextNew>

      <Form onSubmit={handleSubmit}>
        {status.error && <p className={s.mainError}>{status.error}</p>}
        <Input
          name="email"
          type="email"
          id="user_email"
          label={translation('email')}
          placeholder={translation('email')}
          autoComplete="email"
          fullWidth
          lightGrayLabel
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
        />

        <RegionSelector email={values.email} />

        <Box marginY="sm">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {translation('sendInstructionsButton')}
          </Button>
        </Box>

        <Link to="/users/sign_in" className={l.smallLink}>
          {translation('backToLoginLink')}
        </Link>
      </Form>
    </Box>
  )
}

const FormikEnhancedForm = withFormik({
  mapPropsToValues: () => ({ email: '' }),

  handleSubmit: (values, { props, setSubmitting, setStatus }) =>
    props.onSubmit(values, setSubmitting, setStatus),

  validationSchema: props => {
    const { translation } = props

    return yup.object().shape({
      email: yup
        .string()
        .email(translation('emailValidationMessage'))
        .required(translation('emailRequired')),
    })
  },
})(NewPasswordForm)

const FormikWrapper = (props: any) => {
  const { t: translation } = useTranslation('auth', {
    keyPrefix: 'forgotPassword',
  })

  return <FormikEnhancedForm {...props} translation={translation} />
}

export default FormikWrapper
