/* eslint-disable simple-import-sort/sort */
// @flow

// Care UI Pages
import pageHeaderTranslations from 'care-ui/molecules/Heading/PageHeader/localizedStrings.json'

const careUITranslations = {
  en: {
    pageHeaderComponent: pageHeaderTranslations.en,
  },
  fr: {
    pageHeaderComponent: pageHeaderTranslations.fr,
  },
}

export default careUITranslations
