// @flow

import React, { type Node, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import { AccordionItem } from 'react-ui/components/Accordion'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { shortDate } from 'services/dateTime'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button, Text } from 'care-ui'

import { AssessmentRequestsAnswerTableLoader } from '../AssessmentRequestsAnswerTable'

import {
  accordionItemStyle,
  headerStyle,
  noResultsStyle,
} from './AssessmentRequestsQuestionnaire.style'
import { assessmentRequestsQuestionnaireAnswers } from './query/AssessmentRequestsQuestionnaire'

import type { AssessmentRequestsQuestionnaire_answers } from './query/__generated__/AssessmentRequestsQuestionnaire_answers.graphql'

type RequestIdsType = {
  assessmentRequestId: string,
  questionnaireId: string,
}

type PropsType = {
  answeredBy: ?string,
  answers: ?AssessmentRequestsQuestionnaire_answers,
  children?: Node,
  completedAt: ?string,
  isChild?: boolean,
  label: string,
  rating: ?string,
  requestIds?: RequestIdsType,
}

const AssessmentRequestsQuestionnaireBase = (props: PropsType) => {
  const {
    answeredBy,
    answers,
    children,
    completedAt,
    isChild,
    label,
    rating,
    requestIds,
  } = props
  const { current_role: { role_type } } = useCurrentUser()

  const isIndividual = role_type === 'INDIVIDUAL'

  const [isOpen, setOpen] = useState(false)
  const onToggleOpen = () => {
    setOpen(!isOpen)
  }

  const { assessmentRequestId, questionnaireId } = requestIds || {}

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    assessmentRequestId,
    questionnaireId,
    specificQuestionnaireAssessmentTaken: true,
  })

  const { t: translation } = useTranslation('assessment', {
    keyPrefix:
      'assessmentRequestsFeaturesPages.assessmentRequestsQuestionnaire',
  })

  const takeAssessmentButton = (
    <Button onClick={start}>{translation('takeAssessment')}</Button>
  )

  const getStatus = submittedAtDate => {
    if (submittedAtDate) {
      return translation('statusCompleted')
    } else if (isIndividual && requestIds) {
      return takeAssessmentButton
    } else if (isIndividual && answers?.length) {
      return translation('statusAnswered')
    }

    return answers?.length
      ? translation('statusIncomplete')
      : translation('statusUnanswered')
  }

  const getAnsweredByName = () => {
    if (!completedAt) return ''

    return answeredBy
  }

  const submittedAtDate = completedAt
    ? format(shortDate)(new Date(completedAt || ''))
    : ''

  const headingData = () => {
    return (
      <Text
        size="lg"
        color="title"
        extend={headerStyle}
        data-testid="QuestionAnswerRow"
      >
        <div>{label}</div>

        <div data-testid="Rating">{rating}</div>

        <div data-testid="AnsweredOn">{submittedAtDate}</div>

        <div data-testid="Status">{getStatus(submittedAtDate)}</div>

        <div data-testid="AnsweredBy">{getAnsweredByName()}</div>
      </Text>
    )
  }

  const renderAnswers = () => {
    if (children) {
      return children
    }

    return answers?.length ? (
      <AssessmentRequestsAnswerTableLoader answers={answers} />
    ) : (
      <Text size="lg" color="title" extend={noResultsStyle}>
        {translation('noResultsFound')}
      </Text>
    )
  }

  return (
    <AccordionItem
      withArrow
      extend={({ theme }) => accordionItemStyle({ theme, isChild })}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      heading={headingData()}
      data-testid="QuestionSetRow"
    >
      {renderAnswers()}
    </AccordionItem>
  )
}

export const AssessmentRequestsQuestionnaireLoader = createFragmentContainer(
  AssessmentRequestsQuestionnaireBase,
  assessmentRequestsQuestionnaireAnswers,
)
