/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type AssessmentRequestsBulkCreateInput = {|
  clientMutationId?: ?string,
  individuals: IndividualsSelectionInput,
  questionnaireIds: $ReadOnlyArray<string>,
|};
export type IndividualsSelectionInput = {|
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?IndividualsFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type IndividualsFilterInput = {|
  assignedToMe?: ?boolean,
  filterEscalation?: ?boolean,
  helpRequested?: ?boolean,
  prioritiseEscalations?: ?boolean,
  roleStatus?: ?$ReadOnlyArray<string>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type AssessmentRequestsBulkCreateMutationVariables = {|
  input: AssessmentRequestsBulkCreateInput
|};
export type AssessmentRequestsBulkCreateMutationResponse = {|
  +assessmentRequestBulkCreate: ?{|
    +success: boolean
  |}
|};
export type AssessmentRequestsBulkCreateMutation = {|
  variables: AssessmentRequestsBulkCreateMutationVariables,
  response: AssessmentRequestsBulkCreateMutationResponse,
|};
*/


/*
mutation AssessmentRequestsBulkCreateMutation(
  $input: AssessmentRequestsBulkCreateInput!
) {
  assessmentRequestBulkCreate(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssessmentRequestsBulkCreatePayload",
    "kind": "LinkedField",
    "name": "assessmentRequestBulkCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssessmentRequestsBulkCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssessmentRequestsBulkCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "53b8a6a859e6bd1d0be3c0017f6d5fbe",
    "id": null,
    "metadata": {},
    "name": "AssessmentRequestsBulkCreateMutation",
    "operationKind": "mutation",
    "text": "mutation AssessmentRequestsBulkCreateMutation(\n  $input: AssessmentRequestsBulkCreateInput!\n) {\n  assessmentRequestBulkCreate(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6fa36fc29c295486e5388f13f151e1c';

module.exports = node;
